@import 'variables';

.gac-project-details {
  padding-top: 88px;
  padding-bottom: 130px;
  &.gac-mobile-view {
    padding-top: 72px;
    padding-bottom: 30px;
  }
  &.gac-empty-content {
    height: 100vh;
    .gac-project-wrapper {
      height: 100%;
      .gac-messages-empty,
      .gac-project-content {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
    }
  }
}
.gac-project-wrapper {
  display: grid;
  grid-template-columns: 210px 1fr;
  &.gac-content-page {
    grid-template-columns: 39px 1fr;
    .gac-project-sidebar {
      .gac-project-pages {
        position: sticky;
        top: 72px;
        z-index: 5;
        li {
          text-indent: -999999px;
          .gac-count {
            right: -2px;
            top: -2px;
          }
        }
      }
    }
    .gac-project-content {
      margin-right: 218px;
    }
    &.gac-touch-device {
      .gac-project-sidebar *,
      .gac-project-title {
        user-select: none;
      }
    }
  }
  &.gac-ideas-page {
    .gac-project-content {
      .gac-project-title {
        border-bottom: 1px solid $border-color;
      }
    }
  }
  .gac-project-sidebar {
    padding-top: 16px;
    .gac-project-pages {
      position: relative;
      list-style: none;
      li {
        display: flex;
        position: relative;
        padding-left: 32px;
        margin-bottom: 24px;
        line-height: 24px;
        color: $light-text-color;
        transition: color .2s ease;
        cursor: default;
        &:nth-child(1) {
          width: 154px!important;
        }
        &:nth-child(2) {
          width: 121px!important;
        }
        &:nth-child(3) {
          width: 156px!important;
        }
        &:nth-child(4) {
          width: 144px!important;
        }
        &:nth-child(5) {
          width: 141px!important;
        }
        i {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          left: -32px;
          top: 0;
          width: 24px;
          height: 24px;
          svg path {
            transition: fill .2s ease;
          }
        }
        span {
          position: relative;
          user-select: none;
        }
        a {
          position: relative;
          line-height: 24px;
          color: $light-text-color;
          font-size: 15px;
          text-decoration: none;
        }
        .gac-count {
          position: absolute;
          right: -19px;
          top: 2px;
          width: 14px;
          height: 14px;
          background: $red-color;
          color: $white-color;
          font-size: 12px;
          text-align: center;
          line-height: 14px;
          border-radius: 100%;
          text-indent: initial;
        }
        &:not(.gac-disabled):hover, &.gac-active {
          color: $green-color;
          a {
            color: $green-color;
          }
          i {
            svg path {
              fill: $green-color;
            }
          }
        }
        &.gac-disabled {
          opacity: 0.5;
        }
      }
    }
  }
  .gac-project-content {
    border: 1px solid $border-color;
    background-color: $white-color;
    border-radius: 2px;
    &.gac-job-type-design {
      .gac-writer-icons {
        display: none!important;
      }
    }
    &.gac-editing {
      .gac-project-brief {
        padding-top: 32px;
      }
    }
    &.gac-brief-content {}
    &.gac-messages-content {
      border: 0;
      background-color: transparent;
      border-radius: 0;
    }
    .gac-project-title {
      line-height: 40px;
      padding: 32px;
      font-size: 31px;
      font-weight: bold;
    }
    .gac-project-title + .gac-timeline {
      border-top: 1px solid $border-color;
    }
    .gac-project-overview {
      padding: 0 32px;
      .gac-overview-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .gac-overview-field {
          width: calc(50% - 15px);
          margin-bottom: 32px;
          &.gac-mb-6 {
            margin-bottom: 6px;
          }
          &.gac-mb-4 {
            margin-bottom: 4px;
          }
          .gac-overview-label {
            line-height: 16px;
            margin-bottom: 8px;
            color: $light-text-color;
            font-size: 12px;
          }
          .gac-overview-data {
            line-height: 22px;
            &.gac-with-format,
            &.gac-with-status,
            &.gac-with-icon-tags,
            &.gac-with-icon,
            &.gac-data-planned-publish,
            &.gac-with-img {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
            }
            &.gac-with-icon-tags {
              .gac-overview-edit-icon {
                position: relative;
                top: -2px;
                margin-left: 0;
              }
            }
            &.gac-with-icon {
              span {
                margin-right: 11px;
                &:empty {
                  margin-right: 0;
                }
              }
            }
            &.gac-data-planned-publish {
              span {
                margin-right: 11px;
                &:empty {
                  margin-right: 0;
                }
              }
              input {
                background: url('../../images/calendar.svg') no-repeat center right;
              }
            }
            &.gac-with-format {
              .gac-project-type {
                margin-right: 9px;
              }
            }
            .gac-overview-edit-icon {
              width: 13px;
              height: 13px;
              background: url("../../images/sprite.svg") -165px -490px;
              cursor: pointer;
            }
            .gac-plus + .gac-overview-edit-icon {
              margin-left: 9px;
            }
            .gac-plus {
              color: $green-color;
            }
            .gac-img {
              max-width: 30px;
              margin-right: 8px;
              border-radius: 100%;
            }
            .gac-no-img {
              width: 30px;
              height: 30px;
              margin-right: 8px;
              background: url("../../images/sprite.svg") -1px -21px;
              border-radius: 100%;
            }
            a {
              font-size: 15px;
              &:hover {
                color: $green-color;
                text-decoration: none;
              }
            }
            .gac-overview-status {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 22px;
              height: 22px;
              margin: 0 1px;
              i {
                width: 10px;
                height: 10px;
                background: url("../../images/sprite.svg") 0 0;
              }
              &.auto,
              &.approved {
                i {
                  background-position: -175px -115px;
                }
              }
              &.designing,
              &.writing,
              &.revision,
              &.editing,
              &.review {
                i {
                  background-position: -165px -115px;
                }
              }
              &.published {
                i {
                  background-position: -185px -105px;
                }
              }
              &.draft {
                i {
                  background-position: -155px -125px;
                }
              }
              &.matching {
                i {
                  background-position: -155px -115px;
                }
              }
              &.pitching {
                i {
                  background-position: -165px -125px;
                }
              }
              &.approval {
                i {
                  background-position: -165px -105px;
                }
              }
              &.cancelled {
                i {
                  background-position: -155px -105px;
                }
              }
            }
            //.gac-status-name {}
            .gac-extend-date {
              display: flex;
              align-items: center;
              text-transform: capitalize;
              .gac-extend-date-line {
                width: 1px;
                height: 20px;
                margin: 0 7px;
                background-color: $light-text-color;
              }
            }
            .gac-tags-select {
              width: 100%;
            }
            .gac-project-tags {
              display: flex;
              flex-wrap: wrap;
              &:empty {
                padding-right: 0;
              }
              .gac-project-tag {
                line-height: 22px;
                padding: 0 9px;
                margin-right: 4px;
                margin-bottom: 2px;
                border: 1px solid $border-color;
                border-radius: 12px;
                font-size: 12px;
                color: $light-text-color;
                &:last-child {
                  margin-right: 0;
                }
              }
            }
            .gac-daypicker-container {
              input {
                width: 100%;
                height: 48px;
                padding: 0 16px;
                border: 1px solid $border-color;
                background-color: $white-color!important;
                color: $dark-text-color;
                border-radius: 2px;
              }
            }
            .gac-extend-deadline {
              margin-left: 4px;
              color: $light-text-color;
              font-style: normal;
              cursor: pointer;
              text-decoration: underline;
              &:hover {
                text-decoration: none;
              }
            }
            em {
              font-style: normal;
              color: $light-text-color;
            }
          }
        }
      }
    }
    .gac-project-brief {
      padding: 0 32px 8px 32px;
      .gac-brief-wrap {
        .gac-brief-field {
          display: grid;
          grid-template-columns: 174px 1fr;
          margin-bottom: 24px;
          .gac-brief-label {
            padding-right: 10px;
            line-height: 22px;
            font-size: 15px;
            color: $light-text-color;
          }
          .gac-brief-data {
            line-height: 22px;
            overflow: hidden;
            .gac-project-files {
              padding-top: 24px;
              .gac-project-file {
                .gac-project-file-size {
                  margin-right: 6px;
                }
              }
            }
            .gac-invite-writers {
              .gac-writers-list .gac-writer-item {
                cursor: default;
              }
              .gac-writers-plus {
                display: none;
              }
            }
            .gac-brand-profile {
              display: flex;
              align-items: center;
              &-item {
                display: grid;
                grid-template-columns: 24px auto;
                grid-gap: 8px;
                margin-right: 8px;
              }
              .gac-profile-color {
                height: 24px;
                border-radius: 100%;
              }
              .gac-profile-name {
                flex: 1 1 auto;
                text-decoration: underline;
                cursor: pointer;
                &:hover {
                  text-decoration: none;
                }
              }
            }
          }
          .gac-brief-content {
            line-height: 24px!important;
            p {
              min-height: 1.5em;
            }
            a {
              word-break: break-all;
              font-size: 15px;
              &:hover {
                text-decoration: none;
              }
            }
            div,
            p,
            ul,
            ol,
            table {
              line-height: 24px !important;
              color: $dark-text-color!important;
              font-size: 15px !important;
              font-family: $font;
              font-size-adjust: 0.488;
              font-weight: 400;
              font-style: normal;
            }
            ul{
              list-style: disc!important;
            }
            ol{
              list-style: decimal;
            }
            ul,
            ol {
              padding-left: 2em !important;
            }
            ul ul,
            ol ol,
            ul ol,
            ol ul {
              margin: 2px !important;
              padding: 0 !important;
              padding-left: 2em !important;
              border: none;
            }
            table {
              border-collapse: collapse;
              font-size: 1em !important;
              thead td {
                border-bottom: 2px solid #000 !important;
                font-family: $font;
                font-size-adjust: 0.488;
                font-weight: normal!important;
                font-style: normal;
              }
              th {
                font-weight: bold;
                text-align: left;
              }
              td {
                padding: 5px !important;
                border: 1px solid $border-color;
                vertical-align: top;

              }
            }
          }
          &.gac-field-writers {
            .gac-brief-label {
              line-height: 32px;
            }
          }
        }
        .gac-form-row {
          margin-bottom: 24px;
          &.gac-row-multiple {
            display: flex;
            align-items: flex-start;
          }
          .gac-form-field {
            &.gac-field-1-2 {
              width: calc((100% - 28px)/2);
            }
            &.gac-field-1-2 + .gac-field-1-2 {
              margin-left: 28px;
            }
            .gac-form-label {
              display: block;
              line-height: 24px;
              color: $dark-text-color;
              font-size: 14px;
            }
            .gac-form-input {
              input {
                width: 100%;
                height: 48px;
                padding: 0 16px;
                border: 1px solid $border-color;
                background-color: $white-color!important;
                color: $dark-text-color;
                border-radius: 2px;
                &.gac-invalid {
                  border-color:$red-color;
                }
                &.gac-input-calendar {
                  cursor: pointer;
                }
              }
              .gac-warning {
                line-height: 16px;
                margin-top: 9px;
                font-size: 12px;
              }
            }
            .gac-form-radio {
              display: flex;
              &.gac-invalid {
                position: relative;
                &:before,
                &:after {
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 100%;
                  height: 1px;
                  background-color: $red-color;
                  content: "";
                  z-index: 1;
                }
                &:after {
                  top: auto;
                  bottom: 0;
                }
                .gac-radio-item {
                  border-color: $red-color;
                  &:first-child {
                    border-right-color: $border-color;
                  }
                }
              }
              .gac-radio-item {
                position: relative;
                width: 170px;
                height: 48px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $white-color;
                border: 1px solid $border-color;
                color: $dark-text-color;
                cursor: pointer;
                user-select: none;
                &.gac-item-active {
                  background-color: $white-color;
                  &:before {
                    position: absolute;
                    left: -1px;
                    bottom: -1px;
                    width: calc(100% + 2px);
                    height: 2px;
                    border-radius: 2px;
                    background: $green-color;
                    content: "";
                  }
                }
                i {
                  margin-right: 6px;
                  &.gac-star-icon {
                    position: relative;
                    top: -1px;
                  }
                }
                &:first-child {
                  border-radius: 2px 0 0 2px;
                }
                &:last-child {
                  border-radius: 0 2px 2px 0;
                }
              }
              .gac-radio-item + .gac-radio-item {
                border-left: 0;
              }
            }
            .gac-form-content,
            .gac-form-textarea {
              &.gac-invalid {
                .redactor-box {
                  border-color: $red-color;
                }
              }
              .redactor-box,
              .gac-form-content-box {
                min-width: 100%;
                max-width: 716px;
                line-height: 23px!important;
                color: $dark-text-color;
                border: 1px solid $border-color;
                background-color: $white-color;
                border-radius: 2px 2px 0 0;
                .redactor-in,
                .gac-form-content-in {
                  padding: 12px 16px 10px;
                  a {
                    word-break: break-all;
                    font-size: 15px;
                    color: $dark-text-color;
                    &:hover {
                      color: $green-color;
                      text-decoration: none;
                    }
                  }
                  h1, h2, h3, h4, h5, h6 {
                    margin: 0;
                    font-size: 15px;
                    font-weight: normal;
                    font-family: $font;
                  }
                  div,
                  p,
                  ul,
                  ol,
                  table {
                    line-height: 24px !important;
                    color: $dark-text-color!important;
                    font-size: 15px !important;
                    font-family: $font;
                    font-size-adjust: 0.488;
                    font-weight: 400;
                    font-style: normal;
                  }
                  ul{
                    list-style: disc!important;
                  }
                  ol{
                    list-style: decimal;
                  }
                  ul,
                  ol {
                    padding-left: 2em !important;
                  }
                  ul ul,
                  ol ol,
                  ul ol,
                  ol ul {
                    margin: 2px !important;
                    padding: 0 !important;
                    padding-left: 2em !important;
                    border: none;
                  }
                  table {
                    border-collapse: collapse;
                    font-size: 1em !important;
                    thead td {
                      border-bottom: 2px solid #000 !important;
                      font-family: $font;
                      font-size-adjust: 0.488;
                      font-weight: normal!important;
                      font-style: normal;
                    }
                    th, th * {
                      font-weight: bold;
                      text-align: left;
                    }
                    td {
                      padding: 5px !important;
                      border: 1px solid $border-color;
                      vertical-align: top;

                    }
                  }
                }
              }
              textarea {
                display: none;
              }
            }
            .gac-form-field-content {
              display: flex;
              align-items: center;
              &.gac-invalid {
                #planned_publish {
                  border-color: $red-color;
                }
                .gac-timepicker {
                  input {
                    border-color: $red-color;
                  }
                }
              }
              .gac-hint {
                position: relative;
                top: 1px;
                margin-left: 10px;
              }
            }
            .gac-default-textarea {
              textarea {
                min-width: 100%;
                max-width: 716px;
                min-height: 116px;
                line-height: 23px;
                padding: 12px 16px 10px;
                border: 1px solid $border-color;
                color: $dark-text-color;
                border-radius: 2px;
                vertical-align: top;
                &.gac-invalid {
                  border-color: $red-color;
                }
                &:focus {
                  outline: none;
                }
              }
            }
            .gac-form-content {
              .gac-form-content-box {
                border: 0;
                .gac-form-content-in {
                  padding-left: 0;
                  padding-right: 0;
                }
              }
            }
          }
        }
      }
    }
    .gac-project-sharing {
      padding: 0 32px 32px;
      .gac-sharing-wrap {
        display: grid;
        grid-template-columns: 1fr 275px;
        .gac-sharing-links {
          margin-right: 68px;
          .gac-title {
            line-height: 26px;
            margin-bottom: 24px;
            font-size: 20px;
            font-weight: bold;
          }
          .gac-sharing-desc {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            line-height: 22px;
            margin-bottom: 24px;
            i {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 14px;
              height: 17px;
              margin-right: 8px;
              //background: url("../../images/sprite.svg") -145px -610px;
            }
            .gac-sharing-icon-2 {
              background: url("../../images/sprite.svg") -160px -610px;
            }
            strong {
              margin: 0 4px;
            }
          }
          .gac-sharing-link {
            margin-bottom: 40px;
            label {
              line-height: 24px;
              width: 100%;
              font-size: 14px;
              color: $light-text-color;
            }
            .gac-sharing-input {
              position: relative;
              input {
                width: 100%;
                height: 48px;
                padding: 0 16px;
                border: 1px solid $border-color;
                color: $light-text-color;
                border-radius: 2px;
              }
              span, .gac-create-client {
                position: absolute;
                right: 0;
                top: 0;
                height: 48px;
                line-height: 48px;
                padding: 0 35px;
                background: $green-color;
                color: $white-color;
                font-size: 15px;
                border-radius: 2px 0 0 2px;
                cursor: pointer;
                text-decoration: none;
                &.gac-create-client {
                  padding: 0 16px;
                }
                &.gac-copied {
                  top: 56px;
                  padding: 0;
                  line-height: 16px;
                  height: 16px;
                  font-size: 12px;
                  background: transparent;
                  color: $green-color;
                  cursor: default;
                }
              }
            }
          }
        }
        .gac-sharing-info {
          .gac-title {
            line-height: 26px;
            margin-bottom: 24px;
            font-weight: bold;
          }
          ul {
            li {
              position: relative;
              padding-left: 24px;
              line-height: 22px;
              margin-bottom: 8px;
              font-size: 13px;
              &.gac-list-title {
                padding-left: 0;
                &:before {
                  display: none;
                }
              }
              &.gac-mark {
                &:before {
                  width: 11px;
                  height: 8px;
                  background-position: -120px -715px;
                  margin-top: -4px;
                }
              }
              &.gac-plus {
                &:before {
                  background-position: -130px -610px;
                }
              }
              &:before {
                position: absolute;
                left: 2px;
                top: 50%;
                width: 11px;
                height: 11px;
                background: url("../../images/sprite.svg") -110px -610px;
                margin-top: -5px;
                content: "";
              }
            }
          }
        }
      }
      .gac-clients {
        padding-bottom: 10px;
        .gac-title {
          line-height: 22px;
          margin-bottom: 16px;
          strong {
            margin: 0 4px;
          }
        }
        .gac-accounts-container {
          .gac-accounts-item {
            flex: 0 0 calc(50% - 20px);
            max-width: calc(50% - 20px);
            &:nth-child(2n+1) {
              margin-left: 0;
            }
          }
        }
      }
      .gac-activity {
        .gac-title {
          line-height: 26px;
          margin-bottom: 24px;
          font-size: 20px;
          font-weight: bold;
        }
        .gac-activity-list {
          .gac-activity-row {
            display: grid;
            grid-template-columns: 1fr 3fr;
            line-height: 22px;
          }
          .gac-activity-time {
            margin-bottom: 8px;
            white-space: nowrap;
          }
          .gac-activity-desc {
            padding-left: 20px;
          }
          .gac-activity-empty {
            line-height: 22px;
            color: $light-text-color;
          }
        }
      }
    }

    .gac-message-client {
      margin-bottom: 16px;
      border: 1px solid $border-color;
      background-color: $white-color;
      border-radius: 2px;
      .gac-message-form {
        border-top: 1px solid $border-color;
      }
      .gac-pitches {
        .gac-pitch-item {
          margin-bottom: 0;
          border: 0;
          border-top: 1px solid $border-color;
        }
      }
      .gac-project-btns {
        position:sticky;
        bottom:0;
        z-index: 5;
      }
    }
    .gac-pitches {
      .gac-pitch-item {
        margin-bottom: 16px;
        border: 1px solid $border-color;
        background-color: $white-color;
        border-radius: 2px;
        &:last-child {
          margin-bottom: 0;
        }
        .gac-pitch-content {
          padding: 24px 30px 0;
        }
        .gac-pitch-head {
          display: flex;
          align-items: center;
          margin-bottom: 16px;
          .gac-writer-img {
            max-width: 32px;
            height: auto;
            border-radius: 100%;
            margin-right: 8px;
          }
          .gac-no-writer-img {
            width: 32px;
            height: 32px;
            margin-right: 8px;
            border-radius: 100%;
            background: url("../../images/sprite.svg") 0 -20px;
          }
          .gac-writer-name {
            line-height: 22px;
            margin-right: 18px;
            color: $light-text-color;
          }
          .gac-writer-icons {
            display: flex;
            .gac-writer-icon {
              position: relative;
              margin-right: 8px;
              i {
                display: block;
                width: 13px;
                height: 16px;
                background: url("../../images/sprite.svg") -90px -540px;
                &.gac-icon-1 {
                  background-position: -90px -540px;
                }
                &.gac-icon-2 {
                  background-position: -110px -540px;
                }
              }
              .gac-icon-desc {
                position: absolute;
                width: 300px;
                top: 26px;
                left: 50%;
                line-height: 22px;
                padding: 8px 16px 12px;
                background-color: $dark-text-color;
                color: $white-color;
                font-size: 15px;
                font-weight: normal;
                border-radius: 4px;
                transform: translateX(-50%);
                opacity: 0;
                visibility: hidden;
                z-index: 10;
                transition: all .3s ease;
                &:before {
                  position: absolute;
                  left: 50%;
                  top: -6px;
                  margin-left: -9px;
                  width: 0;
                  height: 0;
                  border-left: 9px solid transparent;
                  border-right: 9px solid transparent;
                  border-bottom: 6px solid $dark-text-color;
                  content: "";
                }
              }
              &:hover {
                .gac-icon-desc {
                  opacity: 1;
                  visibility: visible;
                }
              }
            }
          }
          .gac-pitch-date {
            line-height: 16px;
            margin-left: auto;
            font-size: 12px;
            color: $light-text-color;
          }
        }
        .gac-pitch-text {
          padding-bottom: 24px;
          line-height: 22px;
          color: $light-text-color;
          white-space: pre-wrap;
        }
        .gac-project-btns {
          padding-left: 30px;
          padding-right: 30px;
          .gac-btn {
            margin-right: 16px;
          }
        }
        .gac-pitch-showmore {
          width: 24px;
          height: 24px;
          margin: -8px auto 14px;
          background: url("../../images/sprite.svg") -90px -560px;
          cursor: pointer;
        }
        .gac-timeline {
          border-top: 1px solid $border-color;
        }
        .gac-message-form {
          border-top: 1px solid $border-color;
        }
      }
    }
    .gac-project-btns {
      display: flex;
      padding: 24px 32px 8px;
      border-top: 1px solid $border-color;
      background-color: $white-color;
      flex-wrap: wrap;
      &.gac-brief-page-btns,
      &.gac-content-page-btns {
        position:sticky;
        bottom:0;
        z-index: 5;
      }
      .gac-btn {
        line-height: 40px;
      }
      .gac-btn,
      .gac-btn-v3 {
        margin: 0 16px 16px 0;
        font-size: 14px;
      }
      .gac-cutoff-time {
        display: flex;
        padding-bottom: 8px;
        font-size: 14px;
        color: $green-color;
        .gac-hint {
          margin-left: 0;
          margin-right: 6px;
          .gac-hint-wrap .gac-hint-text {
            width: 340px;
            margin-left: 130px;
            &:before {
              margin-left: -139px;
            }
          }
        }
      }
    }
    .gac-share-project {
      padding: 0 32px 32px;
    }
  }
}
.gac-auto-approval {
  display: flex;
  padding-bottom: 8px;
  margin-right: 8px;
  align-items: center;
  white-space: nowrap;
  line-height: 22px;
  color: $green-color;
  font-size: 14px;
  i {
    width: 13px;
    height: 13px;
    margin-right: 8px;
    background: url("../../images/sprite.svg") -210px -680px;
  }
}
.gac-project-files {
  display: grid;
  grid-template-columns: calc((100% - 8px) / 2) calc((100% - 8px) / 2);
  grid-gap: 8px;
  .gac-project-file {
    display: flex;
    align-items: center;
    height: 24px;
    padding: 0 0 0 7px;
    margin: 0;
    background-color: #f3f7f8;
    border-radius: 4px;
    text-decoration: none;
    &:hover {
      color: $dark-text-color;
    }
    .gac-project-file-type {
      flex: 0 0 auto;
      width: 12px;
      height: 16px;
      margin-right: 9px;
      background: url('../../images/sprite.svg') -110px -440px;
      &.jpg,
      &.png,
      &.psd {
        background-position: -122px -440px;
      }
    }
    .gac-project-file-name {
      flex: 1 1 auto;
      line-height: 24px;
      padding-right: 10px;
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .gac-align-right {
      display: flex;
      flex: 0 0 auto;
      align-items: center;
      margin-left: auto;
    }
    .gac-files-item-progress-wrap {
      display: flex;
      align-items: center;
    }
    .gac-files-item-progress-bar {
      position: relative;
      width: 41px;
      height: 6px;
      background-color: $white-color;
      border-radius: 3px;
      span {
        position: absolute;
        left: 0;
        top: 0;
        height: 6px;
        background: $green-color;
        border-radius: 3px;
      }
    }
    .gac-files-item-percentage {
      margin: 0 0 0 6px;
      font-size: 10px;
    }
    .gac-project-file-size {
      color: #8C9EA9;
      font-size: 9px;
      white-space: nowrap;
    }
    .gac-files-item-remove {
      width: 24px;
      height: 24px;
      margin-left: 2px;
      background: url('../../images/sprite.svg') -160px -415px;
      cursor: pointer;
    }
  }
}
.gac-message-form {
  background-color: $white-color;
  position: relative;
  padding: 24px;
  .gac-close {
    position: absolute;
    top: 23px;
    right: 23px;
    width: 24px;
    height: 24px;
    background: url('../../images/sprite.svg') -175px -50px #ffffff;
    cursor: pointer;
  }
  .gac-message-to {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    .gac-message-label {
      margin-right: 16px;
    }
  }
  .gac-message-notify {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    .gac-message-label {
      margin-right: 16px;
    }
  }
  .gac-message-label {
    line-height: 24px;
    font-size: 14px;
    color: $light-text-color;
  }
  .gac-message-textarea {
    textarea {
      display: block;
      min-height: 110px;
      min-width: 100%;
      max-width: 880px;
      line-height: 23px;
      padding: 12px 16px 10px;
      border: 1px solid $border-color;
      border-radius: 2px;
      &:focus {
        outline: none;
      }
      &.gac-invalid {
        border-color: $red-color;
      }
    }
  }
  .gac-project-files {
    padding-top: 6px;
  }
  .gac-error {
    padding-top: 6px;
    font-size: 12px;
  }
  .gac-message-btns {
    padding-top: 24px;
    display: flex;
    .gac-message-dropzone {
      display: flex;
      align-items: center;
      cursor: pointer;
      &:focus {
        outline: none;
      }
    }
    .gac-btn {
      min-width: 120px;
      line-height: 40px;
      margin-right: 15px;
      font-size: 14px;
    }
    .gac-message-upload-file {
      width: 40px;
      height: 40px;
      border: 1px solid $border-color;
      margin-right: 16px;
      background: url("../../images/sprite.svg") -190px -540px;
      border-radius: 100%;
      cursor: pointer;
    }
  }
}
.gac-user-meta {
  display: flex;
  align-items: center;
  margin-right: 10px;
  &.gac-meta-client {
    .gac-user-meta-img,
    .gac-no-user-img {
      margin-left: 0;
    }
  }
  .gac-user-meta-icon {
    position: relative;
    width: 28px;
    height: 28px;
    border: 2px solid $white-color;
    background: $green-color;
    border-radius: 100%;
    &:before {
      position: absolute;
      width: 16px;
      height: 16px;
      left: 4px;
      top: 4px;
      content: "";
    }
    &.gac-client {
      &:before {
        background: url("../../images/sprite.svg") -130px -540px;
      }
    }
    &.gac-writer {
      &:before {
        background: url("../../images/sprite.svg") -150px -540px;
      }
    }
  }
  .gac-user-meta-img {
    max-width: 32px;
    height: auto;
    margin-left: -8px;
    margin-right: 8px;
    border-radius: 100%;
  }
  .gac-no-user-img {
    width: 32px;
    height: 32px;
    margin-left: -8px;
    margin-right: 8px;
    background: url("../../images/sprite.svg") 0 -20px;
    border-radius: 100%;
  }
  .gac-user-meta-name {
    line-height: 22px;
  }
}
.gac-notify-list {
  display: flex;
  .gac-notify-item {
    position: relative;
    margin-right: 8px;
    border-radius: 100%;
    cursor: pointer;
    img {
      max-width: 32px;
      border-radius: 100%;
    }
    .gac-item-overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: $green-color;
      opacity: 0.7;
      border-radius: 100%;
    }
    .gac-item-overlay-icon {
      position: absolute;
      width: 9px;
      height: 7px;
      left: 11px;
      top: 12px;
      background: url("../../images/sprite.svg") -170px -540px;
      z-index: 1;
    }
  }
}
.gac-timeline {
  padding: 15px 0 8px 0;
  .gac-showmore {
    width: 24px;
    height: 24px;
    margin: -10px auto 0;
    background: url("../../images/sprite.svg") -90px -560px;
    cursor: pointer;
  }
  .gac-time {
    line-height: 16px;
    margin-bottom: 16px;
    font-size: 12px;
    color: $light-text-color;
    text-align: center;
  }
}
.gac-note {
  position: relative;
  margin-bottom: 24px;
  background-color: #fcf4e3;
  padding: 16px 30px 16px 47px;
  .gac-note-head {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    .gac-note-icon {
      display: flex;
      margin-right: 4px;
    }
    .gac-avatar img,
    .gac-img {
      max-width: 32px;
      max-height: 32px;
      border-radius: 100%;
    }
    .gac-no-user-img {
      width: 32px;
      height: 32px;
      background: url("../../images/sprite.svg") 0 -20px;
      border-radius: 100%;
    }
    .gac-note-time {
      margin-left: auto;
      margin-bottom: auto;
      line-height: 16px;
      font-size: 10px;
      color: $light-text-color;
      opacity: 0.5;
    }
  }
  .gac-note-content {
    min-height: 44px;
    margin-bottom: 8px;
    line-height: 22px;
    color: $light-text-color;
    white-space: pre-wrap;
  }
  .gac-notify-list {
    justify-content: flex-end;
    .gac-notify-item:last-child {
      margin-right: 0;
    }
    .gac-notify-item {
      .gac-no-avatar {
        width: 24px;
        height: 24px;
        line-height: 24px;
        font-size: 11px;
      }
      img {
        max-width: 24px;
      }
    }
  }
}
.gac-status-changed {
  position: relative;
  display: flex;
  align-items: center;
  height: 42px;
  padding: 0 30px;
  margin-bottom: 24px;
  .gac-line {
    flex: 1 1 auto;
    height: 1px;
    background-color: $disabled-color;
  }
  .gac-status-time {
    margin-left: 7px;
    font-size: 10px;
    line-height: 16px;
    color: $light-text-color;
    opacity: 0.5;
  }
  .gac-status-text {
    position: absolute;
    left: 50%;
    top: 0;
    padding: 9px 20px;
    line-height: 22px;
    border: 1px solid $disabled-color;
    background-color: $white-color;
    color: $green-color;
    border-radius: 20px;
    white-space: nowrap;
    transform: translateX(-50%);
  }
}

.gac-message-block {
  padding: 0 30px;
  margin-bottom: 24px;
  &.gac-message-right {
    display: flex;
    justify-content: flex-end;
  }
  &.gac-message-with-files {
    .gac-message-content {
      border-radius: 2px 2px 0 0;
    }
  }
  .gac-message-wrap {
    max-width: 60%;
    min-width: 250px;
    .gac-notify-list {
      padding-top: 5px;
      justify-content: flex-end;
      .gac-notify-item {
        &:last-child {
          margin-right: 0;
        }
        img {
          max-width: 24px;
        }

        .gac-no-avatar {
          width: 24px;
          height: 24px;
          line-height: 24px;
          font-size: 11px;
        }
      }
    }
  }
  .gac-message-head {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    .gac-message-head-from {
      margin-right: 17px;
      img {
        max-width: 32px;
        height: auto;
        border-radius: 100%;
      }
      .gac-no-user-img {
        width: 32px;
        height: 32px;
        background: url("../../images/sprite.svg") 0 -20px;
        border-radius: 100%;
      }
    }
    .gac-message-head-arrow {
      width: 6px;
      height: 10px;
      margin-right: 15px;
      background: url("../../images/sprite.svg") -164px -187px;
    }
  }
  .gac-message-wrap-2 {
    display: inline-block;
  }
  .gac-message-content {
    min-width: 250px;
    display: inline-block;
    padding: 12px 8px 8px 16px;
    border: 1px solid $disabled-color;
    border-radius: 2px;
    &.gac-empty {
      padding-top: 0;
      padding-bottom: 4px;
      border: 0;
      border-bottom: 1px solid $disabled-color;
    }
    .gac-message-content-wrap {
      line-height: 22px;
      padding-right: 46px;
      color: $light-text-color;
      white-space: pre-wrap;
      word-break: break-word;
    }
    .gac-message-time {
      line-height: 16px;
      font-size: 10px;
      color: $light-text-color;
      opacity: 0.5;
      text-align: right;
    }
  }
  .gac-message-files {
    padding: 4px 8px;
    border: 1px solid $disabled-color;
    border-top: 0;
    .gac-message-file {
      display: flex;
      align-items: center;
      line-height: 16px;
      margin-bottom: 7px;
      &:last-child {
        margin-bottom: 0;
      }
      i {
        width: 12px;
        height: 16px;
        margin-right: 8px;
        background: url('../../images/sprite.svg') -110px -440px;
        &.jpg,
        &.png,
        &.psd {
          background-position: -122px -440px;
        }
      }
      .gac-message-file-name {
        max-width: 212px;
        font-size: 12px;
        color: $dark-text-color;
        overflow: hidden;
        text-overflow: ellipsis;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
      .gac-message-file-size {
        margin-left: auto;
        font-size: 9px;
        color: #8c9ea9;
      }
    }
  }
}
.gac-revision-btns {
  position: sticky;
  top: 55px;
  margin: 0 0 22px;
  padding: 0 46px 10px 45px;
  background-color: $white-color;
  z-index: 5;
  &.no-slider {
    display: flex;
    padding-left: 0;
    padding-right: 0;
    span {
      width: 121px;
    }
  }
  span {
    position: relative;
    line-height: 46px;
    border: 1px solid $disabled-color;
    color: $dark-text-color;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    user-select: none;
    white-space: nowrap;
    &.gac-active {
      &:before {
        position: absolute;
        left: -1px;
        bottom: -1px;
        width: calc(100% + 2px);
        height: 2px;
        background: $green-color;
        content: "";
      }
    }
  }
  span + span {
    border-left: 0;
  }
  .gac-prev,
  .gac-next {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 46px;
    height: 48px;
    border: 1px solid $disabled-color;
    background-color: $white-color;
    z-index: 2;
    cursor: pointer;
  }
  .gac-next {
    width: 46px;
    left: auto;
    right: 1px;
  }
}
.gac-project-content-wrap {
  padding: 0 32px;
  .gac-project-content-meta {
    line-height: 16px;
    padding-bottom: 32px;
    font-size: 12px;
    color: $light-text-color;
    user-select: none;
    div {
      margin-bottom: 6px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    a, span {
      color: $light-text-color;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        text-decoration: none;
        color: $green-color;
      }
    }

  }
  .gac-empty-state-v2 {
    border-top: 0;
    padding-bottom: 62px;
  }
}
.gac-show-changes-btn {
  display: inline-block;
  line-height: 24px;
  margin-bottom: 24px;
  font-size: 12px;
  color: $yellow-color-dark;
  text-transform: uppercase;
  text-decoration: underline;
  cursor: pointer;
  user-select: none;
  &:hover {
    text-decoration: none;
  }
}
.gac-project-redactor {
  margin-bottom: 32px;
  .redactor-box {
    color: $dark-text-color;
  }
  textarea {
    visibility: hidden;
  }
}
.gac-project-data-wrap,
.gac-project-ideas-wrap {
  position: relative;
  margin-bottom: 32px;
  .gac-project-data,
  .gac-project-idea,
  .redactor-box {
    span.gac-selected-text {
      background-color: #fff0b4;
    }
    span.gac-new-comment,
    span.gac-active-comment {
      background-color: #ffe168;
      span.gac-selected-text {
        background-color: #ffe168;
      }
    }
    .gac-new-comment-icon {
      position: relative;
    }
    .diff-html-removed {
      background: #ffe6e6;
      //color: $red-color;
      color: red;
      text-decoration: line-through;
    }
    .diff-html-added {
      background: #e6ffe6;
      text-decoration: none;
    }
  }
  .gac-project-comments {
    position: absolute;
    right: -251px;
    top: 0;
    overflow: hidden;
    z-index: 6;
  }
  .gac-show-comment-form {
    position: fixed;
    width: 22px;
    height: 17px;
    background: url('../../images/comment.png') no-repeat 0 0;
    z-index: 1;
    cursor: pointer;
  }
  .gac-empty-comments-hint {
    position: absolute;
    right: -252px;
    top: 0;
    width: 240px;
    line-height: 22px;
    padding: 8px 16px 12px;
    background-color: $dark-text-color;
    color: $white-color;
    font-size: 14px;
    font-weight: normal;
    border-radius: 4px;
    z-index: 7;
    &:before {
      position: absolute;
      top: 50%;
      left: -6px;
      margin-top: -9px;
      width: 0;
      height: 0;
      border-top: 9px solid transparent;
      border-bottom: 9px solid transparent;
      border-right: 6px solid $dark-text-color;
      content: "";
    }
    p {
      margin-bottom: 12px;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
}
.gac-ai-data,
#gac-project-data,
.gac-idea-data {
  line-height: 24px;
  img {
    max-width: 100%;
    height: auto;
  }
  div,
  p,
  ul,
  ol,
  table,
  dl,
  blockquote,
  pre {
    margin: 0;
    margin-bottom: 10px !important;
    border: none;
    background: none !important;
    box-shadow: none !important;
  }
  /* Elements */
  b, strong {
    font-style: normal !important;
  }
  a {
    word-break: break-all;
    font-size: 15px;
    color: $dark-text-color;
    &:hover {
      text-decoration: none;
      color: $green-color;
    }
  }
  ul {
    padding-left: 2em;
    list-style: disc !important;
  }
  ol {
    padding-left: 2em;
    list-style: decimal!important;
  }
  ul ul,
  ol ol,
  ul ol,
  ol ul {
    margin: 2px !important;
    padding: 0 !important;
    padding-left: 2em !important;
    border: none;
  }
  iframe,
  object,
  hr {
    margin-bottom: 15px !important;
  }
  blockquote {
    margin-left: 3em;
    font-style: italic!important;
  }
  dl dt {
    font-family: $font;
    font-size-adjust: 0.488;
    font-weight: normal!important;
    font-style: normal;
  }
  dd {
    margin-left: 1em;
  }
  table {
    border-collapse: collapse;
    font-size: 1em !important;
    th, th * {
      font-weight: bold;
      text-align: left;
    }
    td {
      padding: 5px !important;
      border: 1px solid $border-color;
      vertical-align: top;
    }
    thead td {
      border-bottom: 2px solid #000 !important;
      font-family: $font;
      font-size-adjust: 0.488;
      font-weight: normal!important;
      font-style: normal;
    }
  }
  code {
    background-color: #d8d7d7 !important;
  }
  pre {
    overflow: auto;
    padding: 1em !important;
    border: 1px solid $border-color!important;
    border-radius: 3px !important;
    background: #f8f8f8 !important;
    white-space: pre-wrap;
    font-size: 90% !important;
  }
  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
  }
  /* Headings */
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-top: 0 !important;
    margin-right: 0 !important;
    margin-left: 0;
    padding: 0 !important;
    background: none;
    color: $dark-text-color;
    font-style: normal;
    font-weight: normal;
  }
  h1 {
    margin-bottom: 10px!important;
    font-size: 24px !important;
    line-height: 30px !important;
  }
  h1 a {
    font-size: 24px !important;
    line-height: 30px !important;
  }
  h2 {
    margin-bottom: 10px!important;
    font-size: 20px !important;
    line-height: 26px !important;
  }
  h2 a {
    font-size: 20px !important;
    line-height: 26px !important;
  }
  h3 {
    margin-bottom: 10px!important;
    font-size: 18px !important;
    line-height: 24px;
  }
  h3 a {
    font-size: 18px !important;
    line-height: 24px;
  }
  h4 {
    margin-bottom: 10px!important;
    font-size: 16px !important;
    line-height: 22px !important;
  }
  h4 a {
    font-size: 16px !important;
    line-height: 22px !important;
  }
  h5 {
    margin-bottom: 10px!important;
    font-size: 1em !important;
  }
  h5 a {
    font-size: 1em !important;
  }

  blockquote:empty,
  div[data-redactor-tag=br]:empty,
  div[data-redactor-tag=tbr]:empty,
  h1:empty,
  h2:empty,
  h3:empty,
  h4:empty,
  h5:empty,
  h6:empty,
  p:empty {
    min-height: 1.5em;
  }
}
.gac-show-mobile-comment-form {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  border-top: 1px solid $border-color;
  background: $white-color;
  z-index: 1050;
  .gac-btn {
    font-size: 14px;
  }
}
.gac-mobile-comment-form {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  border-top: 1px solid $border-color;
  background: $white-color;
  z-index: 1;
}
.gac-project-comments {
  width: 250px;
  &.motion {
    .gac-project-comment {
      position: relative;
      margin-bottom: 8px;
    }
  }
  .gac-project-comment {
    position: absolute;
    left: 10px;
    width: calc(100% - 10px);
    padding: 10px 14px 10px 10px;
    border: 1px solid $border-color;
    background-color: $white-color;
    border-radius: 2px;
    cursor: pointer;
    &.gac-invalid {
      textarea {
        border-color: $red-color;
      }
    }
    &.gac-active,
    &.gac-comment-textarea {
      cursor: default;
      border-top-left-radius: 0;
      &.gac-invalid {
        textarea {
          border-color: $red-color;
        }
      }
      &:after {
        position: absolute;
        border-right: 10px solid #ffffff;
        border-bottom: 10px solid transparent;
        height: 0;
        width: 0;
        left: -9px;
        top: 0;
        content: '';
      }
      &:before {
        position: absolute;
        border-right: 10px solid $border-color;
        border-bottom: 10px solid transparent;
        height: 0;
        width: 0;
        left: -11px;
        top: -1px;
        content: '';
      }
      .gac-line {
        position: absolute;
        top: -1px;
        right: -1px;
        width: 4px;
        height: calc(100% + 2px);
        background-color: $yellow-color;
        content: '';
        border-radius: 2px;
      }
    }
    &.gac-comment-textarea {
      padding-right: 10px;
      padding-bottom: 10px;
    }
    .gac-project-comment-head {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      user-select: none;
      .gac-project-comment-author {
        display: flex;
        align-items: center;
        font-size: 15px;
        color: $dark-text-color;
        img {
          max-width: 30px;
          max-height: 30px;
          width: auto;
          height: auto;
          margin-right: 8px;
          border-radius: 100%;
        }
        .gac-no-avatar {
          position: relative;
          width: 30px;
          height: 30px;
          margin-right: 8px;
          background: url('../../images/sprite.svg') no-repeat -1px -21px #e9eff3;
          border-radius: 100%;
        }
        .gac-name {
          max-width: 118px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      .gac-project-comment-date {
        margin-left: auto;
        font-size: 12px;
        color: $light-text-color;
      }
    }
    p {
      line-height: 16px;
      font-size: 12px;
      max-height: 234px;
      margin-bottom: 6px;
      overflow: auto;
      color: $light-text-color;
      white-space: pre-wrap;
    }
    textarea {
      display: block;
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      line-height: 16px;
      padding: 8px;
      border: 1px solid $border-color;
      border-radius: 2px;
      font-size: 12px;
      color: $light-text-color;
      &:focus {
        outline: none;
      }
    }
    .gac-project-files {
      display: block;
      padding-top: 10px;
      .gac-project-file {
        height: 15px;
        margin-bottom: 5px;
        &:last-child {
          margin-bottom: 0;
        }
        .gac-project-file-type {
          transform: scale(0.7);
          margin-right: 5px;
        }
        .gac-project-file-name {
          line-height: 15px;
          font-size: 10px;
        }
        .gac-align-right {
          height: 15px;
          overflow: hidden;
        }
      }
    }
    .gac-project-comment-btns {
      display: flex;
      align-items: flex-start;
      padding-top: 10px;
      .gac-comment-dropzone {
        display: flex;
        align-items: flex-start;
        margin-left: 10px;
        cursor: pointer;
      }
      & > span {
        display: flex;
        align-items: flex-start;
        cursor: pointer;
        &.gac-cancel {
          margin-left: 10px;
        }
        i {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 10px;
          height: 10px;
          margin-right: 5px;
        }
      }
    }
  }
}
.gac-mobile-comments {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  left: -15px;
  right: -15px;
  padding: 0 15px 10px;
  max-height: calc(100% - 156px);
  background-color: $white-color;
  z-index: 1100;
  box-shadow: 0 -30px 100px 0 rgba(159, 177, 188, 0.3);

  &-head {
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid $border-color;
    .gac-comments-count {
      line-height: 24px;
    }
    .gac-mobile-comments-btns {
      display: flex;
      span {
        width: 24px;
        height: 24px;
        cursor: pointer;
        &:first-child {
          margin-right: 16px;
          background: url("../../images/sprite.svg") -7px -122px;
        }
        &:last-child {
          background: url("../../images/sprite.svg") -7px -162px;
        }
      }
    }
  }
  textarea {
    display: block;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    min-height: 82px;
    line-height: 22px;
    padding: 8px 12px 10px;
    border: 1px solid $border-color;
    border-radius: 2px;
    font-size: 15px;
    color: $light-text-color;
    &:focus {
      outline: none;
    }
  }
  .gac-mobile-comment-wrap {
    padding: 0 16px;
    &.gac-invalid {
      textarea {
        border-color: $red-color;
      }
    }
  }
  .gac-mobile-comment {
    flex: 1 1 auto;
    overflow: auto;
    padding: 0 16px;
    line-height: 22px;
    white-space: pre-wrap;
    a {
      font-size: 15px;
    }
  }
  .gac-comment-area {
    padding: 16px 16px 0;
    &.gac-invalid {
      textarea {
        border-color: $red-color;
      }
    }
  }
  .gac-project-files {
    display: block;
    padding: 16px 16px 0;
    .gac-project-file {
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .gac-comment-actions {
    display: flex;
    padding: 10px 16px 0;
    span {
      display: flex;
      align-items: center;
      line-height: 16px;
      margin-right: 10px;
      font-size: 15px;
      color: $light-text-color;
      cursor: pointer;
      user-select: none;
    }
    .gac-comment-dropzone {
      display: flex;
      margin-right: 10px;
    }
  }
}
.gac-comment-point {
  position: absolute;
  width: 28px;
  height: 28px;
  margin: -14px 0 0 -14px;
  line-height: 22px;
  border: 3px solid $white-color;
  background: $green-color;
  text-align: center;
  color: $white-color;
  font-size: 12px;
  border-radius: 100%;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
  cursor: pointer;
  user-select: none;
  &.gac-active {
    background: $yellow-color;
  }
}
.gac-project-comment-form-title {
  line-height: 26px;
  margin-bottom: 21px;
  font-size: 21px;
  font-weight: bold;
}
.gac-project-comment-form {
  margin-bottom: 24px;
  textarea {
    display: block;
    width: 100%;
    max-width: 100%;
    line-height: 22px;
    height: auto;
    min-height: 88px;
    padding: 6px 10px;
    margin-bottom: 8px;
    border: 1px solid $border-color;
    color: #6f6f7a;
    &:focus {
      outline: none;
    }
  }
  &.gac-invalid {
    textarea {
      border-color: $red-color;
    }
  }
  .gac-error {
    font-size: 14px;
    margin-bottom: 16px;
  }
  .gac-project-files {
    margin-bottom: 8px;
  }
  &-btns {
    display: flex;
    align-items: center;
    .gac-comment-dropzone {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    span {
      padding: 0 14px;
      line-height: 30px;
      background-color: $green-color;
      color: #ffffff;
      font-size: 13px;
      cursor: pointer;
      border-radius: 15px;
      text-decoration: none!important;

      &:hover {
        text-decoration: underline;
      }
      &.gac-project-comment-form__approve {
        margin-right: 10px;
      }
    }
  }
}

.gac-share-project {
  display: flex;
  align-items: center;
  span {
    margin-right: 8px;
  }
  input {
    min-width: 300px;
    height: 40px;
    padding: 0 16px;
    margin-right: 8px;
    border: 1px solid $border-color;
    color: $dark-text-color;
    border-radius: 2px;
  }
  a {
    width: 13px;
    height: 13px;
    background: url("../../images/sprite.svg") -170px -560px;
    text-indent: -999999px;
  }
}

.gac-avatar {
  position: relative;
  border-radius: 100%;
  .gac-no-avatar {
    display: block;
    width: 32px;
    height: 32px;
    line-height: 32px;
    background-color: $disabled-color;
    border-radius: 100%;
    text-align: center;
    font-size: 12px;
    color: #74a3d2;
    font-weight: 600;
    user-select: none;
  }
  .gac-name {
    position: absolute;
    top: -37px;
    left: 50%;
    line-height: 18px;
    padding: 4px 10px 6px;
    background-color: $dark-text-color;
    color: $white-color;
    font-size: 13px;
    font-weight: normal;
    border-radius: 4px;
    transform: translateX(-50%);
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    &:before {
      position: absolute;
      left: 50%;
      bottom: -5px;
      margin-left: -7px;
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-top: 5px solid $dark-text-color;
      content: "";
    }
  }
  &:hover {
    .gac-name {
      opacity: 1;
      visibility: visible;
      z-index: 1;
    }
  }
}

.gac-move-project-block {
  position: relative;
  margin-left: auto;
  span {
    display: block;
    position: relative;
    width: 40px;
    height: 40px;
    border: 1px solid $border-color;
    border-radius: 100%;
    cursor: pointer;
    i {
      position: absolute;
      left: 50%;
      top: 50%;
      margin: -3px 0 0 -5px;
      width: 10px;
      height: 6px;
      background: url("../../images/sprite.svg") -124px -246px;
    }
  }
  .gac-move-project-list {
    position: absolute;
    right: 0;
    top: 40px;
    min-width: 120px;
    max-height: 368px;
    overflow: auto;
    background-color: $white-color;
    border: 1px solid $border-color;
    box-shadow: 0 1px 3px 0 rgba(159, 177, 188, 0.4);
    z-index: 10;
    p {
      line-height: 40px;
      padding: 0 16px;
    }
    ul {
      li {
        display: block;
        position: relative;
        line-height: 40px;
        padding: 0 30px 0 16px;
        border-top: 1px solid $border-color;
        background-color: $white-color;
        color: $light-text-color;
        transition: color .3s ease, background-color .3s ease;
        white-space: nowrap;
        cursor: pointer;
        user-select: none;
        z-index: 1;
        &:before {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: $green-color;
          opacity: 0;
          transition: opacity .2s ease;
          content: '';
          z-index: -1;
        }
        &:hover {
          color: $white-color;
          &:before {
            opacity: 1;
          }
          a {
            color: $white-color;
          }
        }
        a {
          color: $light-text-color;
          font-size: 15px;
          text-decoration: none;
        }
      }
    }
  }
}

/* Ideas */
.gac-project-ideas-wrap {
  padding: 0 32px;
  margin-bottom: 0;
  //border-top: 1px solid $border-color;
  .gac-project-idea {
    padding: 32px 0 16px 47px;
    border-top: 1px solid $disabled-color;
    &:first-child {
      border-top: 0;
    }
    .gac-revision-btns {
      height: 32px;
      padding-bottom: 0;
      margin-bottom: 24px;
      span {
        line-height: 30px;
      }
    }
    .gac-idea-wrap {
      position: relative;
      .gac-idea-status {
        position: absolute;
        top: 4px;
        left: -47px;
        width: 19px;
        height: 18px;
        cursor: pointer;
        background: url("../../images/sprite.svg") 0 -610px;
        &.gac-favorite {
          background-position: -20px -610px;
        }
      }
      .gac-idea-title-wrap {
        display: flex;
        line-height: 26px;
        margin-bottom: 24px;
        font-size: 18px;
        font-weight: 600;
        .gac-idea-number {
          margin-right: 4px;
          user-select: none;
        }
        .gac-idea-title {
          flex: 1 1 auto;
          a {
            font-size: 18px;
            font-weight: 600;
            &:hover {
              text-decoration: none;
              color: $green-color;
            }
          }
        }
      }
      .gac-idea-desc {
        line-height: 22px;
        margin-bottom: 24px;
      }
    }
    .gac-idea-desc + .gac-idea-keywords {
      margin-top: -5px;
    }
    .gac-idea-keywords {
      margin-bottom: 24px;
      user-select: none;
    }
    .gac-idea-keywords + .gac-project-files {
      margin-top: -5px;
      * {
        user-select: none;
      }
    }
    .gac-project-files {
      margin-bottom: 16px;
      .gac-project-file .gac-project-file-size {
        margin-right: 6px;
      }
    }
    .gac-form-row {
      margin-bottom: 24px;
      &.gac-row-multiple {
        display: flex;
        align-items: flex-start;
      }
      .gac-form-field {
        &.gac-field-1-2 {
          width: calc((100% - 28px)/2);
        }
        &.gac-field-1-2 + .gac-field-1-2 {
          margin-left: 28px;
        }
        .gac-form-label {
          display: block;
          line-height: 24px;
          color: $dark-text-color;
          font-size: 14px;
        }
        .gac-form-input {
          input {
            width: 100%;
            height: 48px;
            padding: 0 16px;
            border: 1px solid $border-color;
            background-color: $white-color!important;
            color: $dark-text-color;
            border-radius: 2px;
            &.gac-invalid {
              border-color:$red-color;
            }
          }
          .gac-warning {
            line-height: 16px;
            margin-top: 9px;
            font-size: 12px;
          }
        }
        .gac-form-textarea {
          &.gac-invalid {
            .redactor-box {
              border-color: $red-color;
            }
          }
          .redactor-box {
            min-width: 100%;
            max-width: 716px;
            line-height: 23px!important;
            color: $dark-text-color;
            border: 1px solid $border-color;
            background-color: $white-color;
            border-radius: 2px 2px 0 0;
            .redactor-in {
              padding: 12px 16px 10px;
              a {
                word-break: break-all;
                font-size: 15px;
                color: $dark-text-color;
                &:hover {
                  color: $green-color;
                  text-decoration: none;
                }
              }
              h1, h2, h3, h4, h5, h6 {
                margin: 0;
                font-size: 15px;
                font-weight: normal;
                font-family: $font;
              }
              div,
              p,
              ul,
              ol,
              table {
                line-height: 24px !important;
                color: $dark-text-color!important;
                font-size: 15px !important;
                font-family: $font;
                font-size-adjust: 0.488;
                font-weight: 400;
                font-style: normal;
              }
              ul{
                list-style: disc!important;
              }
              ol{
                list-style: decimal;
              }
              ul,
              ol {
                padding-left: 2em !important;
              }
              ul ul,
              ol ol,
              ul ol,
              ol ul {
                margin: 2px !important;
                padding: 0 !important;
                padding-left: 2em !important;
                border: none;
              }
              table {
                border-collapse: collapse;
                font-size: 1em !important;
                thead td {
                  border-bottom: 2px solid #000 !important;
                  font-family: $font;
                  font-size-adjust: 0.488;
                  font-weight: normal!important;
                  font-style: normal;
                }
                th, th * {
                  font-weight: bold;
                  text-align: left;
                }
                td {
                  padding: 5px !important;
                  border: 1px solid $border-color;
                  vertical-align: top;

                }
              }
            }
          }
          textarea {
            display: none;
          }
        }
      }
    }
    .gac-idea-btns {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      position: relative;
      padding: 6px 0 8px;
      .gac-idea-ordered-meta {
        position: relative;
        padding-left: 23px;
        color: $light-text-color;
        font-size: 12px;
        user-select: none;
        i {
          position: absolute;
          left: 0;
          top: 50%;
          margin-top: -8px;
          width: 14px;
          height: 15px;
          background: url("../../images/sprite.svg") -50px -650px;
          &.gac-icon-1 {
            background-position: -50px -650px;
          }
          &.gac-icon-2 {
            background-position: -70px -650px;
          }
        }
        &:hover {
          text-decoration: none!important;
        }
        a {
          &:hover {
            text-decoration: none!important;
          }
        }
        .gac-daypicker-wrap {
          position: absolute;
          top: 22px;
          left: 0;
          z-index: 7;
          background-color: $white-color;
          box-shadow: 0 10px 50px 0 rgba(159, 177, 188, 0.3);
        }
      }
      & > div:not(.gac-error) {
        margin-left: 16px;
        &:first-child {
          margin-left: 0;
        }
      }
      .gac-error {
        position: absolute;
        left: 0;
        bottom: -8px;
        font-size: 13px;
      }
    }
  }
  .gac-project-comments {
    right: -219px;
  }
}

/* Design */
.gac-preview-images {
  user-select: none;
  &__item {
    margin-bottom: 24px;
    &.gac-with-border {
      position: relative;
      padding-bottom: 32px;
      margin-bottom: 16px;
      &:before {
        position: absolute;
        left: -32px;
        right: -32px;
        bottom: 0;
        height: 1px;
        background-color: $border-color;
        content: "";
      }
    }
  }
  &__wrap {
    display: inline-block;
    position: relative;
    border: 1px solid $border-color;
    & > div:not(.gac-comment-point) {
      display: flex;
    }
    img {
      width: auto;
      max-width: 100%;
    }
    .gac-border-top,
    .gac-border-bottom {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 4px;
      background: $green-color;
    }
    .gac-border-bottom {
      top: auto;
      bottom: 0;
    }
    .gac-border-left,
    .gac-border-right {
      position: absolute;
      left: 0;
      top: 0;
      width: 4px;
      height: 100%;
      background: $green-color;
    }
    .gac-border-right {
      left: auto;
      right: 0;
    }
  }
  &__label {
    line-height: 26px;
    margin-bottom: 21px;
    font-size: 21px;
    font-weight: bold;
  }
}
.gac-preview-images + .gac-error {
  margin-top: -14px;
  font-size: 12px;
}
.gac-types-data {
  display: flex;
  flex-wrap: wrap;
  &__item {
    width: 100%;
    max-width: 198px;
    height: 70px;
    margin-left: 24px;
    margin-bottom: 24px;
    &:nth-child(4n+1) {
      margin-left: 0;
    }
  }
  &__item {
    padding: 0 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $border-color;
    border-radius: 2px;
  }
  &__name {
    max-width: 100%;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .gac-files-item {
    display: flex;
    align-items: center;
    height: 24px;
    padding: 0 0 0 7px;
    margin: 0;
    background-color: #f3f7f8;
    border-radius: 4px;
    overflow: hidden;
    .gac-files-item-type {
      flex: 0 0 auto;
      width: 12px;
      height: 16px;
      margin-right: 9px;
      background: url('../../images/sprite.svg') -110px -440px;
      &.jpg,
      &.png,
      &.psd {
        background-position: -122px -440px;
      }
    }
    .gac-files-item-name {
      flex: 1 1 auto;
      line-height: 24px;
      padding-right: 10px;
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-decoration: none;
      &:hover {
        color: $dark-text-color;
      }
    }
  }
}

.gac-video-comment-title {
  font-weight: bold;
  line-height: 26px;
  margin-top: 15px;
}

.gac-video-comment-text {
  margin-top: 5px;
  font-size: 14px;
  line-height: 20px;
}
